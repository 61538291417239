'use client'
import { SearchType } from '@/app/(site)/search/types'
import { processSearchTerm } from '@/app/util/search-term'
import { useParams } from 'next/navigation'
import { ReactNode, SetStateAction, createContext, useMemo, useState } from 'react'
type SearchContextProps = {
    searchType?: SearchType
    setSearchType: React.Dispatch<SetStateAction<SearchType>>
}

export const SearchTypeContext = createContext({
    searchType: 'gifs',
} as SearchContextProps)

const SearchTypeContextManager = ({ children }: { children: ReactNode }) => {
    const params = useParams()
    const [, type] = params.term ? processSearchTerm(params.term as string) : []
    const [searchType, setSearchType] = useState<SearchType>(type || 'gifs')
    const value = useMemo(() => ({ searchType, setSearchType }), [searchType])
    return <SearchTypeContext.Provider value={value}>{children}</SearchTypeContext.Provider>
}

export default SearchTypeContextManager
